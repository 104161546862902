import React from "react";

// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import { withTheme } from "styled-components";


// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Select from "react-select";

const defaultOptions = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const getCustomStyles = (theme: any) => {
  return {

    // @ts-expect-error TS(7006): Parameter 'provided' implicitly has an 'any' type.
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? theme.colors.secondary : theme.colors.dark,
      backgroundColor: theme.colors.white
    }),
    control: (provided: any, state: any) => {
      return {
        ...provided,
        border: state.menuIsOpen || state.isFocused 
          ? `1px solid ${theme.colors.secondary} !important`
          : `1px solid ${theme.colors.border} !important`,
        borderRadius: 10,
        padding: "1.275rem 1rem",
        outline: "none",
        boxShadow: "none",
      };
    },
  };
};

const SelectStyled = ({
  theme,
  name = "item",
  options = defaultOptions,
  ...rest
}: any) => {
  return (
    <Select
      styles={getCustomStyles(theme)}
      defaultValue={options[1]}
      name={name}
      options={options}
      {...rest}
    />
  );
};

export default withTheme(SelectStyled);
