
// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import { createGlobalStyle } from "styled-components";

const globalStyle = createGlobalStyle`


.on_top {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  height: 30vh;
  width: 100vw;
}

  body {
    background-color: ${({
  theme
}: any) => theme.colors.bg} !important;

    color: ${({
  theme
}: any) => theme.colors.text} !important;
  }

  p, .p{
    color: ${({
  theme
}: any) => theme.colors.text};
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.56px;
    line-height: 30px;
    margin-bottom: 0;
  }

  ul,.ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }


  a {
    /* color: ${({
  theme
}: any) => theme.colors.text} !important; */
    transition: all 0.3s ease-out;
    &:hover, &:active, &:focus{
      /* color: ${({
  theme
}: any) => theme.colors.secondary} !important; */
      text-decoration: none!important;
      outline: none !important;
    }
  }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }
`;

export default globalStyle;
