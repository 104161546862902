import React from "react";
import { Link } from "gatsby";


// @ts-expect-error TS(2307): Cannot find module '../../assets/image/svg/logo_20... Remove this comment to see the full error message
import logo1 from "../../assets/image/svg/logo_2020_dark.svg";

// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import styled from "styled-components";

const StyledLogo = styled.img`
  height: auto;
  width: 100px;
  filter: ${({
  invert
}: any) => (invert ? "invert(1)" : "invert(0)")};
`;

const Logo = ({
  isDark,
  height,
  className = "",
  ...rest
}: any) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      <StyledLogo invert={!isDark} src={logo1} alt="" />
    </Link>
  );
};

export default Logo;
