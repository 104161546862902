exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-left-sidebar-tsx": () => import("./../../../src/pages/blog-left-sidebar.tsx" /* webpackChunkName: "component---src-pages-blog-left-sidebar-tsx" */),
  "component---src-pages-blog-right-sidebar-tsx": () => import("./../../../src/pages/blog-right-sidebar.tsx" /* webpackChunkName: "component---src-pages-blog-right-sidebar-tsx" */),
  "component---src-pages-career-details-tsx": () => import("./../../../src/pages/career-details.tsx" /* webpackChunkName: "component---src-pages-career-details-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-case-study-details-tsx": () => import("./../../../src/pages/case-study-details.tsx" /* webpackChunkName: "component---src-pages-case-study-details-tsx" */),
  "component---src-pages-case-study-tsx": () => import("./../../../src/pages/case-study.tsx" /* webpackChunkName: "component---src-pages-case-study-tsx" */),
  "component---src-pages-coming-soon-2-tsx": () => import("./../../../src/pages/coming-soon-2.tsx" /* webpackChunkName: "component---src-pages-coming-soon-2-tsx" */),
  "component---src-pages-coming-soon-tsx": () => import("./../../../src/pages/coming-soon.tsx" /* webpackChunkName: "component---src-pages-coming-soon-tsx" */),
  "component---src-pages-contact-1-tsx": () => import("./../../../src/pages/contact-1.tsx" /* webpackChunkName: "component---src-pages-contact-1-tsx" */),
  "component---src-pages-contact-2-tsx": () => import("./../../../src/pages/contact-2.tsx" /* webpackChunkName: "component---src-pages-contact-2-tsx" */),
  "component---src-pages-contact-3-tsx": () => import("./../../../src/pages/contact-3.tsx" /* webpackChunkName: "component---src-pages-contact-3-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-old-tsx": () => import("./../../../src/pages/index_old.tsx" /* webpackChunkName: "component---src-pages-index-old-tsx" */),
  "component---src-pages-landing-2-tsx": () => import("./../../../src/pages/landing-2.tsx" /* webpackChunkName: "component---src-pages-landing-2-tsx" */),
  "component---src-pages-landing-4-tsx": () => import("./../../../src/pages/landing-4.tsx" /* webpackChunkName: "component---src-pages-landing-4-tsx" */),
  "component---src-pages-landing-5-tsx": () => import("./../../../src/pages/landing-5.tsx" /* webpackChunkName: "component---src-pages-landing-5-tsx" */),
  "component---src-pages-landing-6-tsx": () => import("./../../../src/pages/landing-6.tsx" /* webpackChunkName: "component---src-pages-landing-6-tsx" */),
  "component---src-pages-landing-7-tsx": () => import("./../../../src/pages/landing-7.tsx" /* webpackChunkName: "component---src-pages-landing-7-tsx" */),
  "component---src-pages-landing-8-tsx": () => import("./../../../src/pages/landing-8.tsx" /* webpackChunkName: "component---src-pages-landing-8-tsx" */),
  "component---src-pages-pricing-1-tsx": () => import("./../../../src/pages/pricing-1.tsx" /* webpackChunkName: "component---src-pages-pricing-1-tsx" */),
  "component---src-pages-pricing-2-tsx": () => import("./../../../src/pages/pricing-2.tsx" /* webpackChunkName: "component---src-pages-pricing-2-tsx" */),
  "component---src-pages-reset-pass-bg-tsx": () => import("./../../../src/pages/reset-pass-bg.tsx" /* webpackChunkName: "component---src-pages-reset-pass-bg-tsx" */),
  "component---src-pages-reset-pass-tsx": () => import("./../../../src/pages/reset-pass.tsx" /* webpackChunkName: "component---src-pages-reset-pass-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-signin-bg-tsx": () => import("./../../../src/pages/signin-bg.tsx" /* webpackChunkName: "component---src-pages-signin-bg-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-signup-bg-tsx": () => import("./../../../src/pages/signup-bg.tsx" /* webpackChunkName: "component---src-pages-signup-bg-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-templates-blog-details-tsx": () => import("./../../../src/templates/blog-details.tsx" /* webpackChunkName: "component---src-templates-blog-details-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */)
}

