import React from "react";

// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import styled from "styled-components";

// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import { color, space, typography, shadow } from "styled-system";

const SpanStyled = styled.span`
  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const Span = ({ ...props }) => {
  return <SpanStyled {...props} />;
};

export default Span;
